export enum Step {
  Password = "password",
  PersonalInfo = "personal-info",
  CompanyInfo = "company-info",
  Subscription = "subscription",
  Final = "final",
  ProjectDetails = "project-details",
  Experience = "experience",
  Education = "education",
}

export enum InvitationType {
  UserInvitation = "UserInvitation",
  ClientOwnerInvitation = "ClientOwnerInvitation",
  ClientAdminInvitation = "ClientAdminInvitation",
  ConsultantInvitation = "ConsultantInvitation",
  SubscriptionUserInvitation = "SubscriptionUserInvitation",
}

export enum InvitationState {
  Accepted = "accepted",
  Pending = "pending",
}

export enum InvitationQueryKeys {
  InvitationType = "invitation_type",
  Token = "token",
}

export const Genders = [
  {
    text: "Male",
    value: "male",
  },
  {
    text: "Female",
    value: "female",
  },
  {
    text: "Prefer not to say",
    value: "prefer_not_to_say",
  },
]
